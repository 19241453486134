<template>
  <div class="fill-height">
    <Navbar />
    <div class="main-body custom-height">
      <CurrentBroadcasts />
      <v-dialog :value="paymentError" persistent max-width="500">
        <v-card>
          <v-card-title class="headline mb-4 pl-4 red--text"><v-layout justify-center>
              <h3 class="p-5 justify-center">
                {{ $t("payment.paymentError") }}
              </h3>
            </v-layout></v-card-title>
          <v-card-text class="vcardtext mt-4 mb-4"><v-layout justify-center>
              <h2>{{ error }}</h2>
            </v-layout>
            <!-- {{ user.grace_period_days }}
          {{ $t("payment.days") }}-->
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="green accent-4" class="green darken-4 green-gradient white--text" @click="payAgain()">
              {{ $t("payment.payAgain") }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <div class="columns-wrapper">
        <div class="columns-wrap-container">
          <div class="columns-wrap-row">
            <div class="col-6">
              <PaymentInfo />
            </div>
            <div class="col-6">
              <div class="panel-right fill-height">
                <PreviousInvoice />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- <v-main class="panel-left light-green lighten-5">
      <v-container fluid class="light-green lighten-5 pt-5 pl-5 pr-5 pb-5">
        <v-row justify="center">
          <v-col primary xs="12" sm="12" md="6" lg="6">
            <PaymentInfo/>
          </v-col>
          <v-col primary xs="12" sm="12" md="6" lg="6">
            <PreviousInvoice/>
          </v-col>
        </v-row>
      </v-container>
    </v-main> -->

    <Footer />
  </div>
</template>

<script>
import Navbar from "../components/layout/Navbar.vue"
import Footer from "../components/layout/Footer.vue"
import CurrentBroadcasts from "../components/CurrentBroadcasts.vue"
import { mapActions, mapGetters } from "vuex";
import { loadStripe } from "@stripe/stripe-js";
import {
  MONTHLY_BY_CARD,
  RECURRING_BY_CARD,
  CASH,
  CHEQUE,
} from "@/constants/common";
import PaymentInfo from "../components/profile/PaymentInfo.vue";
import PreviousInvoice from "./PreviousInvoice.vue";

export default {
  components: {
    Navbar,
    Footer,
    CurrentBroadcasts,
    PreviousInvoice,
    PaymentInfo
  },

  data() {
    return {
      saving: false,
      intentToken: null,
      currentPaymentMethod: null,
      updatePaymentMethod: false,
      cardError: "",
      paymentError: false,
      error: "",
      tab: null,
      paymentButton: false
    };
  },

  computed: {
    ...mapGetters({
      user: "user/getUser",
      setupIntent: "payment/setupIntent",
      paymentMethod: "payment/defaultPaymentMethod",
      loader: "getLoaderStatus",
    }),
    isAssociate() {
      return !!this.user.parent_id;
    },
    hasPaymentMethod() {
      return !!this.paymentMethod;
    },
    isCard() {
      return [RECURRING_BY_CARD, MONTHLY_BY_CARD].includes(
        this.user.user_subscription.payment_method.name
      );
    },
    amount() {
      return this.user.user_subscription.amount + this.user.pending_car_history_payment;
    },
    hasCashOrChequeMethod() {
      return [CASH, CHEQUE].includes(this.user.payment_method);
    },
  },
  async mounted() {
    await this.fetchPaymentMethod();
  },
  methods: {
    ...mapActions({
      fetchSetupIntent: "payment/setupIntent",
      fetchDefaultPaymentMethod: "payment/defaultPaymentMethod",
      savePaymentMethod: "payment/addPaymentMethod",
      getProfile: "user/getProfile",
      addDuePayment: "payment/addDuePayment",
    }),
    async chargeDuePayment() {
      this.paymentButton = true;
      this.$store.commit("setLoader", true);
      try {
        await this.addDuePayment();
        await this.getProfile();
        this.$router.push({ name: "MembersDirectory" });
      } catch ({ message }) {
        this.paymentButton = false;
        this.error = message.custom[0];
        if (message.custom) {
          this.paymentError = true;
        }
        this.apiErrorMessage = message;
      } finally {
        this.$store.commit("setLoader", false);
      }
    },
    async changePaymentMethod() {
      this.updatePaymentMethod = !this.updatePaymentMethod;
      if (this.updatePaymentMethod) {
        await this.initSetupIntent();
        await this.setupCardElement();
      } else {
        await this.fetchPaymentMethod();
      }
    },
    async fetchPaymentMethod() {
      this.$store.commit("setLoader", true);
      try {
        await this.fetchDefaultPaymentMethod();
        this.currentPaymentMethod = this.paymentMethod;
        if (this.currentPaymentMethod === null) {
          await this.changePaymentMethod();
        }
      } catch ({ message }) {
        this.apiErrorMessage = message;
      } finally {
        this.$store.commit("setLoader", false);
      }
    },
    async initSetupIntent() {
      this.$store.commit("setLoader", true);
      try {
        await this.fetchSetupIntent();
      } catch ({ message }) {
        this.apiErrorMessage = message;
      } finally {
        this.$store.commit("setLoader", false);
      }
    },
    async setupCardElement() {
      this.$store.commit("setLoader", true);
      try {
        // SETUP CARD ELEMENT
        const stripe = await loadStripe(process.env.VUE_APP_PUBLIC_KEY);

        const elements = stripe.elements({
          fonts: [
            {
              cssSrc: "https://fonts.googleapis.com/css?family=Source+Code+Pro",
            },
          ],
        });

        const elementStyles = {
          base: {
            color: "#32325D",
            fontWeight: 500,
            fontFamily: "Source Code Pro, Consolas, Menlo, monospace",
            fontSize: "16px",
            fontSmoothing: "antialiased",

            "::placeholder": {
              color: "#7f7f8c",
            },
            ":-webkit-autofill": {
              color: "#7f7f8c",
            },
          },
          invalid: {
            color: "#E25950",

            "::placeholder": {
              color: "#7f7f8c",
            },
          },
        };

        const elementClasses = {
          focus: "focused",
          empty: "empty",
          invalid: "invalid",
        };

        const cardElement = elements.create("card", {
          hidePostalCode: true,
          style: elementStyles,
          classes: elementClasses,
        });

        cardElement.mount("#card-element");

        // VERIFY CARD DETAIL
        const cardHolderName = document.getElementById("card-holder-name");
        const cardButton = document.getElementById("card-button");
        const clientSecret = cardButton.dataset.secret;
        // const clientSecret = this.setupIntent.client_secret;
        cardButton.addEventListener("click", async () => {
          this.saving = true;
          const { setupIntent, error } = await stripe.confirmCardSetup(
            clientSecret,
            {
              payment_method: {
                card: cardElement,
                billing_details: { name: cardHolderName.value },
              },
            }
          );

          if (error) {
            this.saving = false;
            // this.cardError = error.message;
            this.error = error.message;
            this.paymentError = true;
            // Display "error.message" to the user...
            console.log(error);
          } else {
            // The card has been verified successfully...
            await this.saveCardDetails(setupIntent);
          }
        });
      } catch ({ message }) {
        console.log(message);
      } finally {
        this.saving = false;
        this.$store.commit("setLoader", false);
      }
    },

    payAgain() {
      // this.$store.commit("setLoader", true);
      // window.location.reload();
      this.paymentError = false;
    },

    async saveCardDetails(setupIntent) {
      this.$store.commit("setLoader", true);
      try {
        await this.savePaymentMethod({
          payment_method: setupIntent.payment_method,
        });
        this.updatePaymentMethod = false;
        await this.fetchPaymentMethod();
        await this.getProfile();
        this.$router.push({ name: "MembersDirectory" });
      } catch ({ message }) {
        this.error = message.custom[0];
        if (message.custom) {
          this.paymentError = true;
        }
        console.log(message);
      } finally {
        this.saving = false;
        this.$store.commit("setLoader", false);
      }
    },
  },
};
</script>
