<template>
  <div class="panel-inr-box">
    <div class="box-top-wrap d-flex align-center justify-space-between">
      <div v-if="!hasCashOrChequeMethod">
        <h3 class="box-title green-text">
          <span v-if="loader && !saving">
            {{ $t("payment.cardPageIsLoading") }}
          </span>
          <span v-else>
            <button v-if="updatePaymentMethod" @click="updatePaymentMethod = false"
              class="btn customBtn pattern-btn white--text mr-2">
              <span> {{ $t("payment.back") }}</span></button>
            {{
              updatePaymentMethod
                ? $t("payment.addNewCard")
                : $t("payment.currentPaymentMethod")
            }}</span>
        </h3>
      </div>
    </div>
    <v-progress-linear v-if="loader" indeterminate color="primary"></v-progress-linear>
    <!-- <v-container class="pa-0">
   <v-row class="no-gutters elevation-0">
     <v-col cols="auto" class="flex-grow-1 flex-shrink-0">
       <v-responsive class="custom-chat-height-wrapper">
         <v-card class="d-flex flex-column fill-height"> -->
    <!-- <v-card-title class="chat-header" v-if="!hasCashOrChequeMethod">
             <label>
               <span v-if="loader && !saving">
                 {{ $t("payment.cardPageIsLoading") }}
               </span>
               <span v-else>
                 <v-icon
                   v-if="updatePaymentMethod"
                   @click="updatePaymentMethod=false"
                   size="25"
                   class="white--text"
                   >mdi-arrow-left-bold</v-icon
                 >
                 {{
                   updatePaymentMethod
                     ? $t("payment.addNewCard")
                     : $t("payment.currentPaymentMethod")
                 }}</span
               >
             </label>
           </v-card-title>
           <v-progress-linear
             v-if="loader"
             indeterminate
             color="#1b5f20"
           ></v-progress-linear> -->

    <div class="custom-card vehicle-car">
      <div class="custom-box payment-info-wrapper">
        <div class="payment-info-box" v-if="!hasCashOrChequeMethod">
          <v-card-text class="pa-0" v-if="
            hasPaymentMethod &&
            currentPaymentMethod &&
            !updatePaymentMethod
          ">
  
            <!-- <label class="form-label">{{ $t('Card Holder Name') }}</label>
            <v-text-field id="card-holder-name" type="text" hide-details="auto" solo dense
              :placeholder="$t('Card Holder Name')" :value="currentPaymentMethod.billing_details.name"
              disabled="disabled"></v-text-field> -->
            <div class="mt-3 hide-inside">
              <div class="visa-card-wrapper pattern-green pattern-green-after big-card">
                <div class="visa-card-wrapper-inr">
                  <div class="card-chip-wrapper">
                    <img src="./../../assets/chip.png" alt="chip" />
                    <v-icon class="wifi">mdi-wifi</v-icon>
                  </div>
                  <label class="text-h6">
                    &bull; &bull; &bull; &bull; &nbsp;&nbsp; &bull; &bull; &bull; &bull; &nbsp;&nbsp; &bull; &bull;
                    &bull; &bull; &nbsp;&nbsp;
                    {{ currentPaymentMethod.card.last4 }}
                  </label>
                  <div class="card-holder-wrap">
                    <h3 class="card-holder">{{ currentPaymentMethod.billing_details.name }}</h3>
                  </div>
                  <div class="expire-wrap text-right">
                    <span class="mr-2">{{ currentPaymentMethod.card.exp_month }} /
                      {{ currentPaymentMethod.card.exp_year }}</span>
                    <i class="text-3xl fab" :class="'fa-cc-' + currentPaymentMethod.card.brand"
                      style="font-size: 2rem !important;" />
                  </div>
                </div>
              </div>
              <!-- <i class="text-3xl fab card-info" :class="'fa-cc-' + currentPaymentMethod.card.brand" />
              <span class="text-h6">
                &bull; &bull; &bull; &bull;
                {{ currentPaymentMethod.card.last4 }}
                ({{ currentPaymentMethod.card.exp_month }}
                /
                {{ currentPaymentMethod.card.exp_year }})
              </span> -->
            </div>
            <div class="text-center">
              <button class="
                   mt-5 btn customBtn pattern-btn
                   text-capitalize white-text
                    font-16
                 " @click="changePaymentMethod">
                <span><v-icon class="wifi">mdi-cash-edit</v-icon> {{ $t("payment.updateCardDetails") }}</span>
              </button>
            </div>
          </v-card-text>

          <v-card-text class="pa-0" v-if="updatePaymentMethod">
            <label class="form-label">{{ $t('Card Holder Name') }}</label>
            <v-text-field id="card-holder-name" type="text" hide-details="auto" solo dense
              :placeholder="$t('Card Holder Name')" v-model="newCardHolderName" class="mb-4"></v-text-field>
            <!-- Stripe Elements Placeholder -->
            <label class="form-label">{{ $t("payment.cardNumber") }}</label>
            <div class="stripeElement stripeElementNew" id="card-element"></div>
          
            <div class="text-center">
              <button large color="green accent-4" class="
                   mt-5 btn customBtn pattern-btn
                   text-capitalize white-text
                   font-16
                 " id="card-button" :loading="saving" :disabled="saving" :data-secret="setupIntent.client_secret"
                v-if="setupIntent">
                <span> {{ $t("payment.saveDetails") }} </span>
              </button>
            </div>
          </v-card-text>
        </div>

        <div class="payment-information" v-else>
          <span>
            <v-card-title>
              <div class="w-100 text-center">
                <span class="center-image"><img src="../../assets/unautorized.svg" alt="" height="150px" /></span>
                <h5 class="ml-2 mt-4">
                  {{ $t("payment.hasCashOrChequeMethod") }}
                </h5>
              </div>
            </v-card-title>
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { loadStripe } from "@stripe/stripe-js";
import {
  MONTHLY_BY_CARD,
  RECURRING_BY_CARD,
  CASH,
  CHEQUE,
} from "@/constants/common";

export default {
  data() {
    return {
      loader: false,
      saving: false,
      intentToken: null,
      currentPaymentMethod: null,
      updatePaymentMethod: false,
      newCardHolderName: ""
    };
  },

  computed: {
    ...mapGetters({
      user: "user/getUser",
      setupIntent: "payment/setupIntent",
      paymentMethod: "payment/defaultPaymentMethod",
    }),
    hasPaymentMethod() {
      return !!this.paymentMethod;
    },
    isCard() {
      return [RECURRING_BY_CARD, MONTHLY_BY_CARD].includes(
        this.user.user_subscription.payment_method.name
      );
    },
    amount() {
      return this.user.user_subscription.amount + this.user.pending_car_history_payment;
    },
    hasCashOrChequeMethod() {
      return [CASH, CHEQUE].includes(this.user.payment_method);
    },
  },
  async mounted() {
    await this.fetchPaymentMethod();
  },
  
  methods: {
    ...mapActions({
      fetchSetupIntent: "payment/setupIntent",
      fetchDefaultPaymentMethod: "payment/defaultPaymentMethod",
      savePaymentMethod: "payment/newPaymentMethod",
      getProfile: "user/getProfile",
      addDuePayment: "payment/addDuePayment",
    }),

    async chargeDuePayment() {
      this.loader = true;
      try {
        await this.addDuePayment();
      } catch ({ message }) {
        this.apiErrorMessage = message;
      } finally {
        this.loader = false;
        await this.getProfile();
        this.$router.push({ name: "Dashboard" });
      }
    },
    async changePaymentMethod() {
      this.updatePaymentMethod = true;
      await this.initSetupIntent();
      await this.setupCardElement();
    },
    async fetchPaymentMethod() {
      this.loader = true;
      try {
        await this.fetchDefaultPaymentMethod();
        this.currentPaymentMethod = this.paymentMethod;
        console.log('this.paymentMethod',this.paymentMethod);
        
        if (this.currentPaymentMethod === null) {
          await this.changePaymentMethod();
        }
      } catch ({ message }) {
        this.apiErrorMessage = message;
      } finally {
        this.loader = false;
      }
    },
    async initSetupIntent() {
      this.loader = true;
      try {
        await this.fetchSetupIntent();
      } catch ({ message }) {
        this.apiErrorMessage = message;
      } finally {
        this.loader = false;
      }
    },
    async setupCardElement() {
      this.loader = true;
      try {
        // SETUP CARD ELEMENT
        const stripe = await loadStripe(process.env.VUE_APP_PUBLIC_KEY);

        const elements = stripe.elements({
          fonts: [
            {
              cssSrc: "https://fonts.googleapis.com/css?family=Source+Code+Pro",
            },
          ],
        });

        const elementStyles = {
          base: {
            color: "#32325D",
            fontWeight: 500,
            fontFamily: "Source Code Pro, Consolas, Menlo, monospace",
            fontSize: "16px",
            fontSmoothing: "antialiased",

            "::placeholder": {
              color: "#7f7f8c",
            },
            ":-webkit-autofill": {
              color: "#7f7f8c",
            },
          },
          invalid: {
            color: "#E25950",

            "::placeholder": {
              color: "#7f7f8c",
            },
          },
        };

        const elementClasses = {
          focus: "focused",
          empty: "empty",
          invalid: "invalid",
        };

        const cardElement = elements.create("card", {
          hidePostalCode: true,
          style: elementStyles,
          classes: elementClasses,
        });

        cardElement.mount("#card-element");

        // VERIFY CARD DETAIL
        const cardHolderName = document.getElementById("card-holder-name");
        const cardButton = document.getElementById("card-button");
        const clientSecret = cardButton.dataset.secret;
        // const clientSecret = this.setupIntent.client_secret;
        cardButton.addEventListener("click", async () => {
          this.saving = true;

          const { setupIntent, error } = await stripe.confirmCardSetup(
            clientSecret,
            {
              payment_method: {
                card: cardElement,
                billing_details: { name: cardHolderName.value },
              },
            }
          );

          if (error) {
            this.saving = false;
            // Display "error.message" to the user...
            console.log(error);
          } else {
            // The card has been verified successfully...
            await this.saveCardDetails(setupIntent);
          }
        });
      } catch ({ message }) {
        console.log(message);
      } finally {
        this.saving = false;
        this.loader = false;
      }
    },
    async saveCardDetails(setupIntent) {
      this.loader = true;
      try {
        await this.savePaymentMethod({
          payment_method: setupIntent.payment_method,
        });
      } catch ({ message }) {
        console.log(message);
      } finally {
        this.loader = false;
        this.updatePaymentMethod = false;
        await this.fetchPaymentMethod();
        await this.getProfile();
      }
    },
  },
};
</script>
